<template>
	<div :dusk="name">
		<template v-if="type !== 'combobox'">
			<template v-if="hasLabelSlot">
				<label :for="id" :class="['block mb-1 text-sm font-medium text-jmi-purple1', (hideLabel ? 'sr-only' : '')]">
					<slot name="label" />
				</label>
			</template>
			<template v-else>
				<label v-if="label" :for="id" :class="['block mb-1 text-sm font-medium text-jmi-purple1', (hideLabel ? 'sr-only' : '')]">
					<span v-html="label" /> <span v-if="required" class="text-red-500">*</span>
				</label>
			</template>
		</template>

		<Listbox v-if="type === 'listbox'" v-model="computedModelValue" :multiple="multiple">
			<div class="relative h-full w-full">
				<ListboxButton :class="defaultButtonClasses">
					<span class="truncate block mr-4 flex-grow">{{ computedDisplayValue }}</span>
					<template v-if="hasIconSlot">
						<slot name="icon" />
					</template>
					<template v-else>
						<span v-if="!hideChevronUpDownIcon" class="pointer-events-none inset-y-0 flex items-center pr-2 absolute right-0">
							<ChevronUpDownIcon class="h-5 w-5 text-jmi-purple3" aria-hidden="true" />
						</span>
					</template>
				</ListboxButton>

				<transition
						leave-active-class="transition duration-100 ease-in"
						leave-from-class="opacity-100"
						leave-to-class="opacity-0"
				>
					<ListboxOptions
							class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base text-jmi-purple1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
					>
						<ListboxOption
								v-for="option in computedOptions"
								v-slot="{ active, selected }"
								:key="option[fieldId]"
								:value="option"
								as="template"
						>
							<li :dusk="name+'-'+option[fieldId]" :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-jmi-purple2 text-white' : 'text-jmi-purple-1']">
								<div class="flex">
									<span :class="['truncate', selected && 'font-semibold']">
										{{ option[fieldLabel] }}
									</span>
								</div>
								<span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-jmi-purple1']">
									<CheckIcon class="h-5 w-5" aria-hidden="true" />
								</span>
							</li>
						</ListboxOption>
					</ListboxOptions>
				</transition>
			</div>
		</Listbox>

		<div v-if="type === 'checkbox'" class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-3">
			<FormsInternalSwitch
					v-for="option in computedOptions"
					:key="option[fieldId]"
					:label="option[fieldLabel]"
					:dusk="name+'-'+option[fieldId]"
					:model-value="computedCheckedOptions[option[fieldId]]"
					@update:modelValue="setCheckedOption(option[fieldId], $event)"
			/>
		</div>

		<div v-if="type === 'buttons'" :class="['grid gap-x-4', {'grid-cols-2' : computedOptions.length === 2, 'grid-cols-3' : computedOptions.length === 3}]">
			<FormsButton
					v-for="option in computedOptions"
					:key="option[fieldId]"
					:dusk="name+'-'+option[fieldId]"
					:color="computedCheckedOptions[option[fieldId]] ? 'purple' : 'lightPurple'"
					@click="setCheckedOption(option[fieldId], !computedCheckedOptions[option[fieldId]])"
			>
				{{ option[fieldLabel] }}
			</FormsButton>
		</div>

		<Combobox v-if="type === 'combobox'" v-model="computedModelValue" :nullable="required === false" as="div">
			<template v-if="hasLabelSlot">
				<ComboboxLabel :for="id" class="block mb-1 text-sm font-medium text-jmi-purple1">
					<slot name="label" />
				</ComboboxLabel>
			</template>
			<template v-else>
				<ComboboxLabel v-if="label" :for="id" class="block mb-1 text-sm font-medium text-jmi-purple1">
					<span v-html="label" /> <span v-if="required" class="text-red-500">*</span>
				</ComboboxLabel>
			</template>

			<div v-on-click-outside="closeCombobox" class="relative">
				<ComboboxInput
						:id="name"
						ref="comboboxInputRef"
						:class="defaultButtonClasses"
						autocomplete="off"
						:display-value="getComboboxDisplayValue"
						@click="openCombobox"
						@change="updateQuery"
				/>
				<ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<template v-if="hasIconSlot">
						<slot name="icon" />
					</template>
					<template v-else>
						<ChevronUpDownIcon v-if="!hideChevronUpDownIcon" class="h-5 w-5 text-jmi-purple3" aria-hidden="true" @click="openCombobox" />
					</template>
				</ComboboxButton>

				<div v-show="isComboboxOpen">
					<ComboboxOptions v-if="filteredOptions.length > 0" static :class="['absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm', classOptions]">
						<ComboboxOption v-for="option in filteredOptions" :key="option[fieldId]" v-slot="{ active, selected }" :value="option" as="template">
							<li :dusk="name+'-'+option[fieldId]" :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-jmi-purple2 text-white' : 'text-jmi-purple-1']" @click="setCheckedOption(option[fieldId], !computedCheckedOptions[option[fieldId]])">
								<div class="flex">
									<span :class="['truncate', selected && 'font-semibold']">
										{{ option[fieldLabel] }}
									</span>
									<span
											v-if="option[fieldSubLabel]"
											:class="['pl-2', 'truncate', selected && 'font-semibold', active ? 'text-white' : 'text-jmi-purple2']"
									>
										{{ option[fieldSubLabel] }}
									</span>
								</div>
								<span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-jmi-purple1']">
									<CheckIcon class="h-5 w-5" aria-hidden="true" />
								</span>
							</li>
						</ComboboxOption>
					</ComboboxOptions>
				</div>
			</div>
		</Combobox>
		<p v-if="computedErrorMessage" :id="id + '_error'" class="mt-2 text-sm text-jmi-coral1">
			{{ computedErrorMessage }}
		</p>
	</div>
</template>

<script setup lang="ts" generic="FieldId extends string = 'id', FieldLabel extends string = 'label', FieldSubLabel extends string|undefined = undefined">
import {computed, ref, watch, Ref, WritableComputedRef, useSlots, nextTick} from 'vue'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/24/outline'
import {
	Combobox,
	ComboboxButton,
	ComboboxInput,
	ComboboxLabel,
	ComboboxOption,
	ComboboxOptions,
	Listbox,
	ListboxOptions,
	ListboxOption,
	ListboxButton,
} from '@headlessui/vue'
import {FieldMeta, useField} from "vee-validate";
import * as yup from "yup";
import type {AnySchema} from "yup";
import {vOnClickOutside} from '@vueuse/components';

// We have this here to stop this import being marked as unused. It isn't, it's a directive.
vOnClickOutside;

type SelectValue = number | string | boolean | null;

type SelectOption = {
	id: SelectValue;
	label: string;
};

type SelectProps<FieldId extends string, FieldLabel extends string, FieldSubLabel extends string | undefined> = {
	modelValue: SelectValue | SelectValue[] | null;
	options: Array<{
		[key in FieldId]: SelectValue;
	} & {
		[key in FieldLabel]: string;
	} & (FieldSubLabel extends string ? {
		[key in FieldSubLabel]: string;
	} : Record<string, never>) & {
		[key: string]: unknown;
	}>;
	label?: string | null;
	hideLabel?: boolean;
	errorLabel?: string | null;
	fieldId?: FieldId;
	fieldLabel?: FieldLabel;
	fieldSubLabel?: FieldSubLabel;
	name?: string | null;
	placeholder?: string;
	required?: boolean | string;
	multiple?: boolean;
	error?: string | string[] | null;
	extendSchema?: ((obj: AnySchema) => AnySchema) | null;
	type?: "combobox" | "listbox" | "checkbox" | "buttons";
	classOptions?: string;
	inputClass?: string;
	defaultInputClass?: string;
	isTouched?: boolean | undefined;
	dirty?: boolean;
	hideChevronUpDownIcon?: boolean;
};

const props = withDefaults(defineProps<SelectProps<FieldId, FieldLabel, FieldSubLabel>>(), {
	label: null,
	hideLabel: false,
	errorLabel: null,
	fieldId: () => "id" as FieldId,
	fieldLabel: () => "label" as FieldLabel,
	fieldSubLabel: () => undefined as FieldSubLabel | undefined,
	name: null,
	placeholder: "Select option",
	required: false,
	multiple: false,
	error: null,
	extendSchema: null,
	type: "listbox",
	classOptions: "",
	inputClass: "",
	defaultInputClass: "block w-full py-2 text-left px-3 border border-jmi-purple4 bg-white rounded-md shadow-sm focus:outline-none focus:ring-jmi-purple3 focus:border-jmi-purple3 sm:text-sm",
	isTouched: undefined,
	dirty: false,
	hideChevronUpDownIcon: false,
});

const query = ref(undefined as string | undefined);
const updateQuery = (event: InputEvent) => {
	query.value = (event.target as HTMLInputElement).value;
}

watch(query, () => {
	emit('search', query.value?.trim() ?? "", true);
});

const computedOptions = computed(() => {
	return props.options;
});
const slots = useSlots();
const emit = defineEmits(['update:modelValue', 'update:dirty', 'search', 'focus']);
const id = computed(() => {
	return 'select_' + Date.now();
});
const hasLabelSlot = computed(() => {
	return !!slots['label'];
});
const hasIconSlot = computed(() => {
	return !!slots['icon'];
});
const defaultButtonClasses = computed(() => {
	return [props.inputClass, props.defaultInputClass];
});
const computedOptionIds = computed(() => {
	return computedOptions.value.map((option) => {
		return option[props.fieldId];
	});
});
const isComboboxOpen = ref(false);
const openCombobox = () => {
	if (!isComboboxOpen.value) {
		emit('focus');
		isComboboxOpen.value = true;
		query.value = "";
	}
}
const closeCombobox = () => {
	if (isComboboxOpen.value) {
		isComboboxOpen.value = false;

		if (props.modelValue) {
			query.value = lastKnownLabel.value;
		}
	}
}

const lastKnownLabel = ref<string>("");

const getComboboxDisplayValue = function (option: Record<string, any>) {
	if (isComboboxOpen.value || !option) {
		return "";
	} else {
		return option[props.fieldLabel];
	}
}

const computedErrorMessage = computed<string | undefined>(() => {
	if (props.error) {
		if (Array.isArray(props.error)) {
			return props.error.length ? props.error.join(" ") : undefined;
		} else {
			return props.error;
		}
	} else if (meta?.touched) {
		return errorMessage.value;
	} else {
		return undefined;
	}
});
const computedSchema = computed(() => {
	const errorLabel = props.errorLabel ?? props.label;
	let obj: AnySchema;
	if (props.multiple) {
		obj = yup.array();
	} else if (typeof computedOptionIds.value[0] === 'number') {
		obj = yup.number();
	} else if (typeof computedOptionIds.value[0] === 'boolean') {
		obj = yup.boolean();
	} else {
		obj = yup.string();
	}
	obj = obj.nullable();
	if (props.required) {
		if (props.multiple) {
			obj = obj.required(typeof props.required === "string" ? props.required : undefined).of(yup.string()).min(1);
		} else {
			obj = obj.required(typeof props.required === "string" ? props.required : undefined);
		}
	}
	if (errorLabel) {
		obj = obj.label(errorLabel);
	}
	if (props.extendSchema) {
		obj = props.extendSchema(obj);
	}
	return obj;
});

let errorMessage: Ref<string | undefined>;
let meta: FieldMeta<typeof props.modelValue> | undefined = undefined;
if (props.name) {
	const {setValue, setTouched, resetField, errorMessage: errorMessageRef, meta: metaRef} = useField(props.name, computedSchema, {
		initialValue: props.modelValue,
	});
	meta = metaRef;
	errorMessage = errorMessageRef;

	// Reset the field when it mounts, to make sure it doesn't show stale state.
	resetField({
		value: props.modelValue,
		touched: (props.isTouched === undefined) ? false : props.isTouched
	})
	watch(() => props.modelValue, () => {
		setValue(props.modelValue);
		// Only change the touched automatically if the parent component isn't assuming control over it.
		if (props.isTouched === undefined) {
			setTouched(true);
		}
	});
	// If the parent component is controlling the isTouched, erase whatever vee-validate changes.
	if (props.isTouched !== undefined) {
		watch(() => metaRef.touched, () => {
			if (metaRef.touched !== props.isTouched) {
				setTouched(props.isTouched!);
			}
		});
	}
	watch(() => props.isTouched, () => {
		if (props.isTouched !== undefined) {
			setTouched(props.isTouched);
		}
	});

	watch(() => meta?.dirty, (dirty) => {
		if (dirty) {
			emit('update:dirty', dirty);
		}
	});
}
const getOptionById = (id: SelectValue) => {
	let index = computedOptions.value.findIndex((option) => {
		return option[props.fieldId] == id;
	});
	if (index === -1) {
		let obj = {} as Record<string, any>;
		obj[props.fieldId] = null;
		obj[props.fieldLabel] = props.placeholder;
		return obj;
	} else {
		lastKnownLabel.value = computedOptions.value[index][props.fieldLabel];
		return computedOptions.value[index];
	}
};
const computedModelValue: WritableComputedRef<Record<string, any> | Array<Record<string, any>> | null> = computed({
	get() {
		if (props.multiple) {
			if (typeof props.modelValue === 'object' && props.modelValue !== null) {
				return props.modelValue.map((id: string | number | boolean) => {
					return getOptionById(id);
				});
			} else if (typeof props.modelValue === 'undefined' || props.modelValue === null) {
				return [];
			} else {
				throw new Error("Type of modelValue passed to multiselect is invalid. Expected array or null. Got " + (typeof props.modelValue));
			}
		} else {
			if (typeof props.modelValue === 'object' && props.modelValue !== null) {
				throw new Error("Type of modelValue passed to select is invalid. Expected string, number, boolean, or null. Got an object: " + JSON.stringify(props.modelValue));
			} else {
				return getOptionById(props.modelValue);
			}
		}
	},
	set(new_value) {
		let finalValue;
		if (new_value === null || new_value === undefined) {
			if (props.multiple) {
				finalValue = [];
			} else {
				finalValue = null;
			}
		} else if (props.multiple) {
			finalValue = new_value.map((option: Record<string, any>) => {
				return option[props.fieldId];
			});
		} else {
			finalValue = new_value[props.fieldId];
		}

		closeCombobox();
		emit('update:modelValue', finalValue);
	}
});
const computedCheckedOptions = computed(() => {
	let options = {} as Record<string, boolean>;
	computedOptions.value.forEach((option) => {
		options[option[props.fieldId]] = false;
	});
	if (props.multiple) {
		(props.modelValue as Array<string>).forEach((id: string) => {
			options[id] = true;
		});
	} else {
		options[props.modelValue as string] = true;
	}
	return options;
});
const setCheckedOption = (id: string, value: boolean) => {
	if (props.multiple) {
		let currentValue = computedModelValue.value as Array<Record<string, any>>;
		let existingIndex = currentValue.findIndex((option: Record<string, any>) => {
			return option[props.fieldId] == id;
		});
		if (value) {
			if (existingIndex === -1) {
				// Value isn't in modelValue and should be, add:
				currentValue.push(getOptionById(id));
				computedModelValue.value = currentValue;
			}
		} else {
			if (existingIndex !== -1) {
				// Value is in modelValue and should NOT be, remove:
				currentValue.splice(existingIndex, 1);
				computedModelValue.value = currentValue;
			}
		}
	} else {
		computedModelValue.value = getOptionById(id);
	}
};
const computedDisplayValue = computed(() => {
	let currentValue;
	if (props.multiple) {
		currentValue = computedModelValue.value as Array<Record<string, any>>;
		if (currentValue.length === 0) {
			return props.placeholder;
		} else {
			return (props.modelValue as Array<string>).map((id: string) => {
				return getOptionById(id)[props.fieldLabel];
			}).join(", ");
		}
	} else {
		currentValue = computedModelValue.value as Record<string, any> | null;
		return (currentValue !== undefined && currentValue !== null) ? currentValue[props.fieldLabel] : props.placeholder;
	}
});
const filteredOptions = computed(() =>
	(query.value === '' || query.value === undefined)
		? computedOptions.value
		: computedOptions.value.filter((option) => {
			if (option[props.fieldSubLabel] && option[props.fieldLabel]) {
				return option[props.fieldLabel].toLowerCase().includes(query.value!.toLowerCase()) || option[props.fieldSubLabel].toLowerCase().includes(query.value!.toLowerCase());
			}
			return option[props.fieldLabel].toLowerCase().includes(query.value!.toLowerCase());
		})
);

// This fixes a bug where after selecting an option, the input would still be focused.
// This meant that on mobile the keyboard would still be showing.
// It also caused our code that wipes the input on focus to run again, which made for weird behaviour when selecting an option.
// See: https://github.com/tailwindlabs/headlessui/issues/1555
const comboboxInputRef = ref<InstanceType<typeof ComboboxInput> | null>(null);
watch(() => props.modelValue, () => {
	nextTick(() => {
		setTimeout(() => comboboxInputRef.value?.$el.blur(), 0);
	});
});
</script>
